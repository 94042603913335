import { useMemo, useRef } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';

import type { TooltipProps } from '@mui/material';

import styles from './TooltipStyles';

type Props = TooltipProps & {
  variant?: 'tooltip' | 'confirmation' | 'admin' | 'description'
  placement?: TooltipProps['placement']
};

const useStyles = makeStyles({ name: 'Tooltip' })(styles);

const getTooltipStyles = (variant: string): [string, boolean] => {
  let tooltipClass = '';
  let isOpaque = false;

  switch (variant) {
    case 'tooltip':
      tooltipClass = 'tooltip';
      break;
    case 'confirmation':
      tooltipClass = 'confirmation';
      break;
    case 'admin':
      tooltipClass = 'admin';
      break;
    case 'description':
      tooltipClass = 'description';
      isOpaque = true;
      break;
    default:
      tooltipClass = 'tooltip';
  }

  return [tooltipClass, isOpaque];
};

const Tooltip = (props: Props) => {
  // Props
  const { className, variant = 'tooltip', placement = 'bottom-end', ...otherProps } = props;
  const tooltipRef = useRef(null);

  // Hooks
  const { classes, cx } = useStyles();
  const [tooltipClass, isOpaque] = useMemo(() => getTooltipStyles(variant), [variant]);

  return (
    <MuiTooltip
      classes={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        tooltip: cx(classes[tooltipClass], className),
        popper: cx({ [classes.opaque]: isOpaque }),
      }}
      placement={placement}
      ref={tooltipRef}
      {...otherProps}
    />
  );
};

export default Tooltip;
