import { alpha } from '@mui/material/styles';

import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme, _, classes: { [key: string]: string }) => {
  const { baseUnit, palette } = theme;

  return {
    button: {
      color: palette.icons.gray,
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.main,
      },
    },
    onFocus: {
      color: palette.primary.main,
    },
    icon: {
      height: baseUnit * 6,
      width: baseUnit * 6,
    },
    secondaryIconButton: {
      color: palette.icons.gray,
      '&:hover': {
        color: palette.icons.gray,
        backgroundColor: alpha(palette.icons.gray, 0.15),
      },
      [`&.${classes.onFocus}`]: {
        color: palette.icons.gray,
        backgroundColor: alpha(palette.icons.gray, 0.15),
      },
    },
  };
};

export default styles;
