import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const AttachmentSpreadsheet = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M26.616 18.654V7.962h.206a4 4 0 013.075 1.443l5.35 6.431a4 4 0 01.924 2.558v.26h-9.555z"
      fill="#8BD0AF"
    />
    <path
      d="M30.669 18.654a4 4 0 01-4-4V7.962H13.633a3.622 3.622 0 00-3.623 3.635v24.806a3.622 3.622 0 003.623 3.635h18.915a3.622 3.622 0 003.623-3.635V18.654h-5.502z"
      fill="#1CA261"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 24h7v3h-7v-3zm0 4h7v3h-7v-3zm-1 3v-3h-8v3h8zm-8 1v3h8v-3h-8zm9 0h7v3h-7v-3zm-1-8v3h-8v-3h8zm-9-1h18v13H14V23z"
      fill="#fff"
    />
  </svg>
));

AttachmentSpreadsheet.displayName = 'AttachmentSpreadsheet';

export default AttachmentSpreadsheet;
