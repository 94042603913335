import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Menu = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <line
        stroke={color}
        strokeWidth={strokeWidth}
        transform="matrix(1 0 0 -1 4 17)"
        x2={16}
        y1={-1}
        y2={-1}
      />
      <line
        stroke={color}
        strokeWidth={strokeWidth}
        transform="matrix(1 0 0 -1 4 12)"
        x2={16}
        y1={-1}
        y2={-1}
      />
      <line
        stroke={color}
        strokeWidth={strokeWidth}
        transform="matrix(1 0 0 -1 4 7)"
        x2={16}
        y1={-1}
        y2={-1}
      />
    </svg>
  );
});

Menu.displayName = 'Menu';

export default Menu;
