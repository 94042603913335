import { forwardRef } from 'react';
import { ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { FC } from 'react';
import type { ButtonBaseProps } from '@mui/material';
import type IconProps from 'components/ui/Atoms/Icons/iconTypes';

import styles from './IconButtonStyles';

export type IconButtonProps = ButtonBaseProps & {
  Icon: FC,
  label: string,
  iconClassName?: string,
  className?: string,
  color?: string,
  iconProps?: IconProps,
};

const useStyles = makeStyles({ name: 'IconButton' })(styles);

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { Icon, className, iconClassName, label, color, iconProps = {}, ...otherProps } = props;
  const { classes, cx } = useStyles();

  return (
    <ButtonBase
      type="button"
      className={cx(classes.button, { [classes.secondaryIconButton]: color === 'secondary' }, className)}
      classes={{ focusVisible: classes.onFocus }}
      aria-label={label}
      ref={ref}
      {...otherProps}
    >
      <Icon
        className={cx(classes.icon, iconClassName)}
        data-cy="icon-button-icon"
        {...iconProps}
      />
    </ButtonBase>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
