import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const LockOpen = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M5.5 10a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v6a3.5 3.5 0 0 1-3.5 3.5H9A3.5 3.5 0 0 1 5.5 16v-6z"
        stroke={color}
      />
      <path
        d="M14.5 9a.5.5 0 0 0 1 0h-1zm-6-2a.5.5 0 0 0 1 0h-1zm6 0v2h1V7h-1zM12 4.5A2.5 2.5 0 0 1 14.5 7h1A3.5 3.5 0 0 0 12 3.5v1zM9.5 7A2.5 2.5 0 0 1 12 4.5v-1A3.5 3.5 0 0 0 8.5 7h1zM12.5 13a.5.5 0 0 0-1 0h1zm-1 3a.5.5 0 0 0 1 0h-1zm0-3v3h1v-3h-1z"
        fill={color}
      />
    </svg>
  );
});

LockOpen.displayName = 'LockOpen';

export default LockOpen;
