import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const StopwatchRush = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      data-cy="stopwatch-rush"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M14.115 19a6.346 6.346 0 0 0 0-12.692"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M14.192 6.308V4"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.885 3.5h2.461M19.308 8.741L21.048 7M14.039 12.731H16.5M4.808 10.423h5.923M2.5 6.962h8.231M7.115 13.885h3.616M9.423 17.347h1.308"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

StopwatchRush.displayName = 'StopwatchRush';

export default StopwatchRush;
