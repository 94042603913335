import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { shouldTrack, trackLocation } from 'helpers/analytics';

const AnalyticsRouteTracker = () => {
  const { hash, search, pathname } = useLocation();

  useEffect(() => {
    if (shouldTrack()) trackLocation({ pathname, search, hash });
  }, [pathname, search, hash]);

  return null;
};

export default AnalyticsRouteTracker;
