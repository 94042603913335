import { forwardRef } from 'react';

import { palette } from "theme";

import type IconProps from './iconTypes';

const Award = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = palette.primary.main, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      data-cy="award"
      ref={ref}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42 16.07L7 24l5.004-2.327L17 24l-1.42-7.932a1.852 1.852 0 0 0-1.904.89l-.08.131c-.716 1.215-2.472 1.215-3.189 0l-.078-.13a1.869 1.869 0 0 0-1.909-.889z"
        fill={color}
      />
      <path
        d="M13.597 1.91l.079.13a1.87 1.87 0 0 0 2.062.857l.148-.035c1.364-.35 2.613.891 2.255 2.254l-.035.149a1.85 1.85 0 0 0 .856 2.061l.131.079c1.215.716 1.215 2.472 0 3.189l-.13.079a1.869 1.869 0 0 0-.857 2.061l.035.149c.35 1.363-.891 2.612-2.255 2.254l-.148-.035a1.85 1.85 0 0 0-2.062.856l-.08.131c-.716 1.215-2.472 1.215-3.189 0l-.078-.13a1.87 1.87 0 0 0-2.062-.857l-.149.035c-1.363.35-2.613-.891-2.255-2.254l.035-.149a1.85 1.85 0 0 0-.856-2.062l-.131-.078c-1.215-.717-1.215-2.473 0-3.189l.14-.079a1.852 1.852 0 0 0 .847-2.061l-.035-.149c-.35-1.363.892-2.612 2.255-2.254l.149.035c.803.21 1.642-.14 2.062-.848l.078-.13c.717-1.224 2.473-1.224 3.19-.01z"
        fill={color}
      />
    </svg>
  );
});

Award.displayName = 'Award';

export default Award;
