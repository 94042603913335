import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme) => {
  const { baseUnit, palette, typography } = theme;
  const adminText = 'rgba(0, 0, 0, 0.87)';
  const descriptionBoxShadow = 'rgba(0, 0, 0, 0.15)';

  const defaultStyles = {
    ...typography.caption1,
    backgroundColor: palette.icons.dark,
    border: `1px solid ${theme.palette.icons.gray}`,
    color: palette.common.white,
    borderRadius: 4,
    padding: `${baseUnit + 2}px ${baseUnit * 3}px`,
    maxWidth: 288,
    lineHeight: '14px',
  };

  return {
    tooltip: {
      ...defaultStyles,
    },
    confirmation: {
      ...defaultStyles,
      backgroundColor: palette.alert.low,
      border: `1px solid ${palette.alert.low}`,
    },
    description: {
      ...typography.body1,
      backgroundColor: palette.background.secondary,
      boxShadow: `0px 0px 8px 2px ${descriptionBoxShadow}`,
      color: palette.text.primary,
      lineHeight: 1.26,
      maxHeight: 200,
      maxWidth: 400,
      overflow: 'auto',
      padding: baseUnit * 3,
    },
    admin: {
      backgroundColor: palette.background.secondary,
      color: adminText,
      border: `1px solid ${palette.border.main}`,
      maxWidth: 800,
      '& pre': {
        fontSize: '1.4rem', // admin only
      },
    },
    // Popper styles
    opaque: {
      opacity: 1,
    },
  };
};

export default styles;
