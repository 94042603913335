import { alpha } from '@mui/material/styles';

import type { BatonTheme } from 'theme';

const trackColor = alpha('#221F1F', 0.26);
const knobColor = '#F1F1F1';
const disabledColor = alpha('#D5D5D5', 0.5);

const styles = (theme: BatonTheme, _, classes: { [key: string]: string }) => {
  const { baseUnit, palette, mixins } = theme;

  return {
    root: {
      alignItems: 'center',
      display: 'flex',
      height: 14,
      position: 'relative',
      width: baseUnit * 7,
    },
    track: {
      background: trackColor,
      borderRadius: 4,
      height: 10,
      transition: 'all 0.25s ease-in',
      width: baseUnit * 6,
    },
    knob: {
      ...mixins.position('absolute', '50%', 0, 0, 0),
      background: knobColor,
      borderRadius: '50%',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)',
      display: 'block',
      height: 14,
      transform: 'translate(0, -50%)',
      transition: 'all 0.25s ease-in',
      width: 14,
    },
    // States
    on: {
      [`& .${classes.track}`]: {
        background: alpha(palette.primary.main, 0.5),
      },
      [`& .${classes.knob}`]: {
        background: palette.primary.main,
        transform: 'translate(80%, -50%)',
      },
    },
    disabled: {
      [`& .${classes.track}`]: {
        background: disabledColor,
      },
      [`& .${classes.knob}`]: {
        background: knobColor,
      },
    },
    disabledOn: {
      [`& .${classes.track}`]: {
        background: alpha(palette.primary.main, 0.25),
      },
      [`& .${classes.knob}`]: {
        background: '#9EABFF',
      },
    },
  };
};

export default styles;
